<template>
<CRow>
    <CCol sm="12">
        <CCard>                 
            <CCardHeader class="pt-2 pb-2">    
                <div class="clearfix mb-0 pb-0">             
                    <h4 class="pt-1 pb float-left">Footer Links</h4>          
                    <CButton v-if="canEdit" color="primary" class="float-right" @click="onNewLink"><CIcon name="cilPlus" class="mt-0 mb-1" />&nbsp;Add Link</CButton>
                </div>  
            </CCardHeader>
            <CCardBody class="pt-2">
                <CAlert :show="hasError" color="danger" class="mt-2 mb-0" >Ein Fehler ist aufgetreten. Links konnten nicht geladen werden.</CAlert>
                <CDataTable
                    :items="links"
                    :items-per-page="100"
                    :loading="loading"  
                    addTableClasses="mb-0 pb-2 mt-3 channels-table"  
                     :fields="[
                     { key:'title', label: 'Title' }, 
                     { key:'type', label: 'Type' },
                     { key:'target', label: 'Link Target' },                      
                     { key:'translations', label: 'Translations' },
                     { key:'actions', label: 'Actions',  _style: 'width: 310px;' }
                    ]"              
                >
                    <template #type="{item}">
                        <td class="text-black" style="color: black;">
                            <CIcon v-if="item.type=='Page'" name="cil-spreadsheet" size="lg" class="mr-1 page-icon mb-1" /> 
                            <CIcon v-if="item.type=='External'" name="cilLinkAlt" size="lg" class="mr-1 page-icon mb-1" /> 
                            {{item.type == 'Page' ? 'Content Page' : (item.type == 'External' ? 'External URL' : '')}}
                        </td>
                    </template>
                    <template #title="{item}">
                        <td>
                            <CLink v-if="canEdit" @click="onEdit(item.index)" >{{item.title}}<CIcon name="cil-pencil" size="sm" class="ml-1 mr-0 mb-1" /></CLink>
                            <span v-else>{{ item.title }}</span>
                        </td>
                    </template>
                    <template #target="{item}">
                        <td>
                            <CLink v-if="item.page && item.type=='Page'" class="text-info" :to="`/pages/${item.page}`">{{item.page}}</CLink>
                            <a v-if="item.url && item.type=='External'" :href="item.url" target="_blank">{{item.url}}<CIcon name="cil-external-link" size="sm" class="ml-1 mr-0 mb-1" /></a>
                        </td>
                    </template>
                     <template #translations="{item}">
                        <td>
                            <span v-for="lang in languages.filter(l => hasTranslation(item, l))" :key="lang"
                                :class="getTranslationTypeClass(item, lang)"
                            >{{lang }}&nbsp;
                                <CLink v-if="getUrl(item, lang)" :href="getUrl(item, lang)" target="_blank"><CIcon name="cil-external-link" size="sm" class="ml-0 mr-2 mb-1" /></CLink>
                            </span>
                        </td>
                    </template>
                    <template #actions="{item}">
                        <td>
                            <CButton v-if="canEdit" size="sm" color="info" class="p-1 pr-2 pl-2 action-btn" @click="onEdit(item.index)"><CIcon name="cil-pencil" size="sm" class="m-0 mr-0 p-0" style="transform: translateY(-2px)" /> Edit</CButton>
                            <CButton v-if="canEdit" size="sm" color="success" class="ml-2 p-1 pr-2 pl-2 action-btn" @click="onUp(item.index)" :disabled="item.index == 0"
                            ><CIcon name="cil-chevron-circle-up-alt" size="sm" class="m-0 mr-1 p-0" style="transform: translateY(-2px)" /> Up</CButton>
                            <CButton v-if="canEdit" size="sm" color="success" class="ml-2 p-1 pr-2 pl-2 action-btn" @click="onDown(item.index)" :disabled="item.index == (links.length - 1)"
                            ><CIcon name="cil-chevron-circle-down-alt" size="sm" class="m-0 mr-0 p-0" style="transform: translateY(-2px)" /> Down</CButton>
                            <CButton v-if="canEdit" size="sm" color="danger" class="ml-2 p-1 pr-2 pl-2 action-btn" @click="onConfirmDelete(item.index)"><CIcon name="cil-trash" size="sm" class="m-0 mr-0 p-0" style="transform: translateY(-3px)" /> Delete</CButton>
                        </td>
                    </template>
                </CDataTable>
            </CCardBody>
        </CCard>
    </CCol>
    <EditLinkModal ref="editor" />
    <CModal
        title="Delete link?"
        color="danger"
        :show.sync="showConfirmDelete"
        >
        Do you really want to remove the link?
        <template #footer>
            <CButton @click="onCancel">Cancel</CButton>
            <CButton color="danger" @click="onDelete(selectedIndex)">Delete</CButton>
       </template>
        </CModal>
</CRow>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/links/actions';
import EditLinkModal from '@/components/modals/EditLinkModal'
import { EDIT_CONTENT, mapPermissions } from '@/permissions';

export default {
   components: {
      EditLinkModal
   },
   data() {
       return {
           selectedIndex: -1,
           showConfirmDelete: false
       }
   },
   methods: {       
        hasTranslation(item, lang) {
           if (item.translations && item.translations[lang]) {
               const tl =  item.translations[lang];               
               return tl && (tl.url || tl.title || tl.hidden);
           }
        },
        getUrl(item, lang) {
            const tl =  item.translations[lang];
            return tl.url;
        },
        getTranslationTypeClass(item, lang) {
            const tl =  item.translations[lang];
            if (tl.hidden) return 'text-secondary';
            if (tl.url) return 'text-primary';
            return 'text-black';
        },
        ...mapActions({
            loadLinks: actionNames.LIST_LINKS,
            moveLink: actionNames.MOVE_LINK,
            deleteLink: actionNames.DELETE_LINK,
        }),
        onEdit(index) {
            const link = this.links[index];
            this.$refs.editor.edit(link);
        },
        onConfirmDelete(index) {
            this.selectedIndex = index;
            this.showConfirmDelete = true;
        },
        onCancel() {
            this.selectedIndex = -1;
            this.showConfirmDelete = false;
        },
        async onUp(index) {
            await this.moveLink({from:index, to:index-1});
        },
        async onDown(index) {
            await this.moveLink({from:index, to:index+1});
        },
        async onDelete(index) {
            this.onCancel();
            await this.deleteLink({index});
        },
        onNewLink() {
            this.$refs.editor.create();
        }
    },
   computed: {
        ...mapPermissions({
            canEdit: EDIT_CONTENT
        }),
        ...mapGetters({ 
            merchantId: 'merchantId',
            links: 'links/links',
            hasError: 'links/hasError',
            loading: 'links/loading',
            languages: 'links/languages',
            pages: 'links/pages'            
        })
    },
   created() {
       this.loadLinks();
   }
}
</script>

<style scoped>
.action-btn {
    min-width: 64px;
}
</style>
