var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"clearfix mb-0 pb-0"},[_c('h4',{staticClass:"pt-1 pb float-left"},[_vm._v("Footer Links")]),(_vm.canEdit)?_c('CButton',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.onNewLink}},[_c('CIcon',{staticClass:"mt-0 mb-1",attrs:{"name":"cilPlus"}}),_vm._v(" Add Link")],1):_vm._e()],1)]),_c('CCardBody',{staticClass:"pt-2"},[_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":_vm.hasError,"color":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Links konnten nicht geladen werden.")]),_c('CDataTable',{attrs:{"items":_vm.links,"items-per-page":100,"loading":_vm.loading,"addTableClasses":"mb-0 pb-2 mt-3 channels-table","fields":[
                     { key:'title', label: 'Title' }, 
                     { key:'type', label: 'Type' },
                     { key:'target', label: 'Link Target' },                      
                     { key:'translations', label: 'Translations' },
                     { key:'actions', label: 'Actions',  _style: 'width: 310px;' }
                    ]},scopedSlots:_vm._u([{key:"type",fn:function(ref){
                    var item = ref.item;
return [_c('td',{staticClass:"text-black",staticStyle:{"color":"black"}},[(item.type=='Page')?_c('CIcon',{staticClass:"mr-1 page-icon mb-1",attrs:{"name":"cil-spreadsheet","size":"lg"}}):_vm._e(),(item.type=='External')?_c('CIcon',{staticClass:"mr-1 page-icon mb-1",attrs:{"name":"cilLinkAlt","size":"lg"}}):_vm._e(),_vm._v(" "+_vm._s(item.type == 'Page' ? 'Content Page' : (item.type == 'External' ? 'External URL' : ''))+" ")],1)]}},{key:"title",fn:function(ref){
                    var item = ref.item;
return [_c('td',[(_vm.canEdit)?_c('CLink',{on:{"click":function($event){return _vm.onEdit(item.index)}}},[_vm._v(_vm._s(item.title)),_c('CIcon',{staticClass:"ml-1 mr-0 mb-1",attrs:{"name":"cil-pencil","size":"sm"}})],1):_c('span',[_vm._v(_vm._s(item.title))])],1)]}},{key:"target",fn:function(ref){
                    var item = ref.item;
return [_c('td',[(item.page && item.type=='Page')?_c('CLink',{staticClass:"text-info",attrs:{"to":("/pages/" + (item.page))}},[_vm._v(_vm._s(item.page))]):_vm._e(),(item.url && item.type=='External')?_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url)),_c('CIcon',{staticClass:"ml-1 mr-0 mb-1",attrs:{"name":"cil-external-link","size":"sm"}})],1):_vm._e()],1)]}},{key:"translations",fn:function(ref){
                    var item = ref.item;
return [_c('td',_vm._l((_vm.languages.filter(function (l) { return _vm.hasTranslation(item, l); })),function(lang){return _c('span',{key:lang,class:_vm.getTranslationTypeClass(item, lang)},[_vm._v(_vm._s(lang)+" "),(_vm.getUrl(item, lang))?_c('CLink',{attrs:{"href":_vm.getUrl(item, lang),"target":"_blank"}},[_c('CIcon',{staticClass:"ml-0 mr-2 mb-1",attrs:{"name":"cil-external-link","size":"sm"}})],1):_vm._e()],1)}),0)]}},{key:"actions",fn:function(ref){
                    var item = ref.item;
return [_c('td',[(_vm.canEdit)?_c('CButton',{staticClass:"p-1 pr-2 pl-2 action-btn",attrs:{"size":"sm","color":"info"},on:{"click":function($event){return _vm.onEdit(item.index)}}},[_c('CIcon',{staticClass:"m-0 mr-0 p-0",staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-pencil","size":"sm"}}),_vm._v(" Edit")],1):_vm._e(),(_vm.canEdit)?_c('CButton',{staticClass:"ml-2 p-1 pr-2 pl-2 action-btn",attrs:{"size":"sm","color":"success","disabled":item.index == 0},on:{"click":function($event){return _vm.onUp(item.index)}}},[_c('CIcon',{staticClass:"m-0 mr-1 p-0",staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-chevron-circle-up-alt","size":"sm"}}),_vm._v(" Up")],1):_vm._e(),(_vm.canEdit)?_c('CButton',{staticClass:"ml-2 p-1 pr-2 pl-2 action-btn",attrs:{"size":"sm","color":"success","disabled":item.index == (_vm.links.length - 1)},on:{"click":function($event){return _vm.onDown(item.index)}}},[_c('CIcon',{staticClass:"m-0 mr-0 p-0",staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-chevron-circle-down-alt","size":"sm"}}),_vm._v(" Down")],1):_vm._e(),(_vm.canEdit)?_c('CButton',{staticClass:"ml-2 p-1 pr-2 pl-2 action-btn",attrs:{"size":"sm","color":"danger"},on:{"click":function($event){return _vm.onConfirmDelete(item.index)}}},[_c('CIcon',{staticClass:"m-0 mr-0 p-0",staticStyle:{"transform":"translateY(-3px)"},attrs:{"name":"cil-trash","size":"sm"}}),_vm._v(" Delete")],1):_vm._e()],1)]}}])})],1)],1)],1),_c('EditLinkModal',{ref:"editor"}),_c('CModal',{attrs:{"title":"Delete link?","color":"danger","show":_vm.showConfirmDelete},on:{"update:show":function($event){_vm.showConfirmDelete=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.onDelete(_vm.selectedIndex)}}},[_vm._v("Delete")])]},proxy:true}])},[_vm._v(" Do you really want to remove the link? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }