<template>
<CModal
    color="primary"
    :title="title"
    :closeOnBackdrop='false'
    :show.sync="showModal"    
    size="lg"
    class="p-3"
>
    <LinkEditor ref="editor" :languages="languages" :pages="pages" class="ml-3" />

    <template #footer>
        <div v-if="loading" class="mr-auto d-flex align-items-center">
            <CSpinner class="mr-3" size="sm" /><strong>Saving link ...</strong>
        </div>
        <CButton :disabled='loading' color="secondary" @click="onCancel">Cancel</CButton>
        <CButton :disabled='loading' color="success" @click="onSave">Save Link<CSpinner size="sm" class="ml-2" v-if="loading" /></CButton>
    </template>
</CModal>
</template>
<script>
import LinkEditor from '@/components/LinkEditor'
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            title: "New Footer Link",
            showModal: false,
            index: -1,
        }
    },    
    components: {
        LinkEditor
    },
    computed: {
        ...mapGetters({
            loading: 'links/loading',
            languages: 'links/languages',
            pages: 'links/pages'
        })
    },
    methods: {
        /* eslint-disable */
        edit(link) {
            this.title = "Edit Footer Link";
            this.index = link.index;
            this.$refs.editor.setLink(link);
            this.showModal = true;            
        },
        create() {
            this.title = "New Footer Link";
            this.index = 1000;
            this.$refs.editor.new();
            this.showModal = true;
        },
        onCancel() {
            this.showModal = false;
        },
        async onSave() {
            await this.$refs.editor.save();
            this.showModal = false;
        }
    }
}
</script>