<template>
    <div>
        <div class="form-group form-row mt-1 mb-0 ">
            <div class="col-sm-1 label-col">
                <label class="col-form-label strong" >Link Text</label>
            </div>
            <div class="pl-0 ml-0 mb-0 col-sm-5 mr-3">
                <CInput class="mb-0 mr-0" add-input-classes="text-primary"
                        placeholder="E.g. Data Protection Policy, Imprint ..." 
                        v-model="link.title" 
                        invalidFeedback="This is a required field"
                        :isValid="isTitleValid"        
                        :disabled='loading'                
                />
            </div>
        </div>
        <div class="form-group form-row mt-1 mb-0 ">
            <div class="col-sm-1 label-col">
                <label class="col-form-label strong" >Link Type</label>
            </div>
            <div class="col-sm-6 form-check form-check-inline" role="group">
                <CInputRadio label="Content Page" name="g1" class="ml-0 pl-0 mr-3" value="Page" 
                             :checked.sync="pageChecked" 
                             :disabled='loading'
                />
                <CInputRadio label="External URL" name="g1" class="ml-0 pl-0" value="External" 
                            :checked.sync="externalChecked" 
                            :disabled='loading'
                />
            </div>
        </div>

        <div v-if="link.type == 'Page'" class="form-group form-row mt-1 mb-0 ">
            <div class="col-sm-1 label-col">
                <label class="col-form-label">Content Page</label>
            </div>
            <div class="col pl-0 ml-0">
                <CSelect v-if="hasPages" class="mb-0 ml-0 page-select col-sm-5 pl-0"
                        :add-input-classes="{'text-primary': link.page}"
                        placeholder="Select Page:"
                        :options="pageOptions"
                        :value.sync="link.page"
                        :isValid="isPageValid" 
                        :disabled='loading'
                ></CSelect>
                <div v-else class="col pl-0">
                    <div class="form-control ml-0 pl-0" style="border-color:transparent">
                        <span class="text-warning">You don't have created any content pages yet.</span>&nbsp;
                        <CLink to="/pages" class="strong" :disabled='loading'>Click here</CLink> to create a new content page.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="link.type == 'External'" class="form-group form-row mt-1 mb-0 ">
            <div class="col-sm-1 label-col">
                <label class="col-form-label">External URL</label>
            </div>
            <span class="col ml-0 mr-1 pl-0 mb-0">
                <CInput class="mb-0"  add-input-classes="text-primary" label=""
                        placeholder="https://..." 
                        v-model="link.url" 
                        :is-valid="false"
                        invalidFeedback="This field is required and must be a valid URL"
                        :isValid="isUrlValid"
                        :disabled='loading'
                />
            </span>
        </div>

        <div v-if="hasTranslations" class="form-group form-row mt-3 mb-0">
            <div class="col-sm-1 label-col">
                <label class="col-form-label strong">Translations</label>
            </div>
            <div class="pl-0 ml-0 col">
                <CTabs ref="tabs" addTabsWrapperClasses="pl-0 pr-0 pt-0 mt-0" addTabsClasses="p-3 border-left border-bottom border-right" class="mr-1"                
                       :activeTab.sync="tabIndex"
                       @update:activeTab="onTabChanged"
                       :disabled='loading'
                >
                    <CTab v-for="lang in languages" :key="lang" :disabled='loading'>
                        <template slot="title">
                            <span :class="getTabTitleClasses(lang)">{{ lang }}</span>
                        </template>
                        <template>                                                  
                            <CInputCheckbox label="Hide link in this language" class="ml-1" 
                                :checked.sync="translations[lang].hidden" 
                                :disabled='loading'
                            />       
                            <CAlert v-if="!isTranslated(lang)" color="warning" class="mb-2 mt-2">If you leave this empty, the default title <span v-if="link.type == 'External'">and url</span> will be used</CAlert>   
                            <CInput v-if="!translations[lang].hidden" 
                                    class="mb-0 mt-2" addLabelClasses="strong pl-1" add-input-classes="text-primary"
                                    placeholder="Link Text Translation" label="Link Text" 
                                    v-model="translations[selectedLang].title" 
                                    :isValid="isTitleValidOrEmpty"
                                    :disabled='loading'
                            />
                            <CInput v-if="!translations[lang].hidden && link.type == 'External'" 
                                    class="mb-0 mt-2"  addLabelClasses="strong pl-1" add-input-classes="text-primary"
                                    label="External URL"
                                    :placeholder="link.url" 
                                    v-model="translations[selectedLang].url" 
                                    :isValid="isUrlValidOrEmpty"
                                    :disabled='loading'
                            />
                        </template>
                    </CTab>
                </CTabs>    
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/links/actions';
import { validateUrl } from '@/utils';

export default {
    props: {
        lang: String,
        languages: Array,
        pages: Array
    },
    data() {
        return {
            externalChecked: false,
            pageChecked: true,
            tabIndex: 0,
            canTranslateTitle: true,
            // -- 
            selectedLang: null,   
            link: {
                title: null,
                url: null,
                page: null,
                type: "External"          
            },
            translations: {
                // hidden: false, 
                // title: null, 
                // url: null
            },
            linkIndex: 1000
        }
    },
    computed: {
        hasTranslations() {
            return this.translations && this.languages && this.languages.length > 0;
        },
        pageOptions() {
            const options = this.pages.map(p => { return { value:p, label: p }});
            return options;
        },
        hasPages() {
            return this.pages && this.pages.length > 0;
        },
        ...mapGetters({
            loading: 'links/loading'
        })
    },
    methods: {
        ...mapActions({
            saveLink: actionNames.SAVE_LINK
        }), 
        initialize() {
            const index = this.languages ? this.languages.indexOf(this.lang) : -1;
            if (index >= 0) {
                this.tabIndex = index;
                this.selectedLang = this.lang;
            }
            else {
                this.tabIndex = 0;
                this.selectedLang = this.languages && this.languages.length > 0 ? this.languages[0] : null;
            }
            
            if (this.languages) {        
                const o = this;
                this.languages.forEach(l => {
                    Vue.set(o.translations, l, { hidden: false, title: null, url: null });
                });
            }
            this.pageChecked = this.link.type == 'Page';
            this.externalChecked = !this.pageChecked;
            this.updateType();
        },
        setLink(link) {
            this.link = {
                title: link.title,
                url: link.url,
                page: link.page,
                type: link.type
            };            
            this.linkIndex = Number.isInteger(link.index) ? link.index : 1000;
            this.initialize();            

            let translations = JSON.parse(JSON.stringify(link.translations));
            if (!translations) translations = {};           

            // set translations
            if (this.languages) {        
                const o = this;
                this.languages.forEach(l => {
                    Vue.set(o.translations, l, translations[l] ? translations[l] : { hidden: false, title: null, url: null });
                });
            }
        },
        new()
        {
            this.linkIndex = 1000;
            this.link = {
                title: '',
                url: null,
                page: null,
                type: this.pages && this.pages.length > 0 ? 'Page' : 'External'
            };  
            this.translations = {};
            this.initialize();
        },
        onTabChanged(index)
        {
            this.selectedLang = this.languages ? this.languages[index] : null;
        },
        getTabTitleClasses(lang) {
            const classes = [`lang-${lang}`];
            if (lang == this.selectedLang)  { classes.push('strong'); }

            if (this.isHidden(lang))  { classes.push('text-secondary'); }
            else if (!this.isValid(lang))  { classes.push('text-danger'); }
            else if (!this.isTranslated(lang))  { classes.push('text-warning'); }
            return classes;
        },
        isTranslated(lang) {
            if (this.translations && this.translations[lang])
            {
                const tl = this.translations[lang];
                return tl.title || tl.url || tl.hidden;
            } 
            else { return false; }
        },
        updateType() {
            if (this.pageChecked /* && this.hasPages*/) {
                this.link.type = 'Page'
                this.externalChecked = false;
                this.pageChecked = true;
            }
            else {
                this.link.type = 'External'
                this.externalChecked = true;
                this.pageChecked = false;
            }
        },        
        isHidden(lang) {
            return (this.translations && this.translations[lang]) ? this.translations[lang].hidden : false;
        },
        isTitleValid(text) {
            const valid = !!text && text.length > 2;
            return valid ? null : false;
        },
        isTitleValidOrEmpty(text) {
            return text ? text.length == 0 || this.isTitleValid(text) : null;
        },
        isUrlValid(text) {
            const valid = validateUrl(text);
            return valid ? null : false;
        },
        isUrlValidOrEmpty(text) {
            return text ? text.length == 0 || this.isUrlValid(text) : null;
        },
        isPageValid(value) {
            return this.pages && this.pages.includes(value) ? null : false;
        },
        /* eslint-disable */
        isValid(lang) {
            if (this.translations && this.translations[lang]) {
                const tl =  this.translations[lang];
                if (tl.hidden) {
                    return true;
                }
                else 
                {                    
                    if (this.isTitleValidOrEmpty(tl.title) === false) 
                    {
                        return false;
                    }
                    if (this.isUrlValidOrEmpty(tl.url) === false) 
                    {
                        return false;
                    }
                    return true;
                }
            }
        },
        isAllValid() {
            if (this.isTitleValid(this.link.title) === false) { return false; }
            if (this.link.type == 'External' && this.isUrlValid(this.link.url) === false) { return false; }
            else if (this.link.type == 'Page' && this.isPageValid(this.link.page) === false) { return false; }
            
            if (!this.languages || this.languages.length == 0){
                return true;
            }

            const hasInvalid = this.languages.filter(l => this.translations[l] && !this.translations[l].hidden).find(l => !this.isValid(l));
            return !hasInvalid;            
        },
        async save() {
           if (this.isAllValid()) 
           {
                const dto = JSON.parse(JSON.stringify(this.link));
                if (this.hasTranslations) {                    
                    dto.translations = JSON.parse(JSON.stringify(this.translations));
                }

                await this.saveLink({link:dto, index: this.linkIndex});
           }
        }
    },
    watch: {
        externalChecked(checked) {
            if (checked) {
                this.pageChecked = false;
                this.updateType();
            }
        },
        pageChecked(checked) {
            if (checked) {
                this.externalChecked = false;
                this.updateType();
            }
        },
        languages(languages) {
            if (languages && languages.length > 0) 
            {
                this.selectedLang = languages[0];    
                const o = this;
                this.translations = {};
                this.languages.forEach(l => {
                    Vue.set(o.translations, l, { hidden: false, title: null, url: null });
                });
            }
        }
    },
    created() {
    }
}
</script>

<style scoped>
.text-black {
    color: black;
}
.label-col {
    min-width: 120px;
}
.border-left {
    border-left: solid 1px #c4c9d0 !important;
}
.border-bottom {
    border-bottom: solid 1px #c4c9d0 !important;
}
.border-right {
    border-right: solid 1px #c4c9d0 !important;
}
</style>
<style>
.page-select select {
    background-position-x: calc(100% - 18px) !important;
}
</style>
